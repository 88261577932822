<template>
  <div>
    <v-row>
      <v-col
        v-if="showViewAsCustomer"
        cols="4"
        lg="3"
        class="pb-1"
      >
        <ViewAsCustomer />
      </v-col>
      <v-col
        cols="4"
        lg="3"
        class="pb-1"
      >
        <v-menu
          ref="dateMenu"
          v-model="specialtyEngagementBase.dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText(specialtyEngagementBase.dates)"
              label="Month range"
              prepend-icon="mdi-calendar-range"
              readonly
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="info"
                      :disabled="!comparisonValueSelected"
                      v-on="on"
                      @click="swapDates"
                    >
                      mdi-swap-horizontal
                    </v-icon>
                  </template>
                  <span>Swap with Comparison Dates</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="specialtyEngagementBase.dates"
            range
            type="month"
            :min="minMonth.local().format()"
            :max="currentMonth"
            show-current
          />
        </v-menu>
      </v-col>
      <v-col
        cols="4"
        lg="3"
        class="pb-1"
      >
        <v-select
          v-model="comparisonValue"
          :items="comparisonItems"
          label="Compared to"
          outlined
          dense
          hide-details
          clearable
        />
      </v-col>
      <v-col
        cols="4"
        lg="3"
        class="pb-1"
      >
        <v-menu
          ref="dateMenu2"
          v-model="specialtyEngagementComparison.dateMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="dateRangeText(specialtyEngagementComparison.dates)"
              :disabled="!comparisonValueSelected"
              label="Month range"
              prepend-icon="mdi-calendar-range"
              readonly
              single-line
              hide-details
              v-bind="attrs"
              v-on="on"
            >
              <template v-slot:append>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      color="info"
                      :disabled="!comparisonValueSelected"
                      v-on="on"
                      @click="swapDates"
                    >
                      mdi-swap-horizontal
                    </v-icon>
                  </template>
                  <span>Swap with Main Dates</span>
                </v-tooltip>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="specialtyEngagementComparison.dates"
            range
            type="month"
            :min="minMonth.local().format()"
            :max="currentMonth"
            show-current
          />
        </v-menu>
      </v-col>
      <v-col
        cols="12"
        class="d-flex justify-center align-end"
      >
        <div class="text-h4">
          {{ title1 }}
        </div>
        <div class="text-h6 font-italic">
          {{ title2 }}
        </div>
      </v-col>
      <v-col
        cols="12"
        class="dx-viewport"
      >
        <DxTreeList
          v-if="!specialtyEngagementBase.specialtyEngagementsLoading && specialtyEngagementBase.specialtyEngagements"
          id="specialtyEngagements"
          :style="{ maxHeight: windowMixin_containerMinHeight - 100 + 'px'}"
          :data-source="specialtyEngagementBase.specialtyEngagements"
          :root-value="-1"
          :expanded-row-keys="expandedRowKeys"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          column-resizing-mode="nextColumn"
          :show-row-lines="true"
          :show-borders="true"
          :column-auto-width="true"
          filter-mode="fullBranch"
          key-expr="index"
          parent-id-expr="parentIndex"
        >
          <DxFilterRow
            :visible="true"
          />
          <DxRemoteOperations
            :filtering="true"
            :sorting="true"
            :grouping="true"
          />
          <!-- <DxFilterBuilderPopup :position="filterBuilderPopupPosition" /> -->
          <DxSearchPanel :visible="true" />
          <DxHeaderFilter :visible="true" />
          <DxFilterPanel
            :visible="true"
          />
          <DxSelection
            mode="single"
          />
          <DxColumn
            data-field="displayName"
            caption="Marketing Offer/Specialty"
            :allow-hiding="false"
            :allow-reordering="false"
            :fixed="true"
          />
          <DxColumn
            data-field="received"
            caption="Received"
            cell-template="CTReceived"
          />
          <!--sort-order="desc"-->
          <DxColumn
            data-field="receivedRate"
            caption="Received Distribution (%)"
            data-type="number"
            format="percent"
            cell-template="CTReceivedRate"
          >
            <!-- :header-filter="{dataSource: percentHeaderFilters}" :filter-values="[0, 100]" -->
            <DxFormat
              type="fixedPoint"
              :precision="2"
            />
            <DxHeaderFilter
              :group-interval="10"
            />
          </DxColumn>
          <DxColumn
            data-field="readRate"
            caption="Read Rate (>2s) (%)"
            data-type="number"
            format="percent"
            cell-template="CTReadRate"
          >
            <DxFormat
              type="fixedPoint"
              :precision="2"
            />
          </DxColumn>
          <DxColumn
            data-field="openRate"
            caption="Open Rate (%)"
            data-type="number"
            format="percent"
            cell-template="CTOpenRate"
          >
            <DxFormat
              type="fixedPoint"
              :precision="2"
            />
          </DxColumn>
          <DxColumn
            data-field="clickRate"
            caption="Click Rate (%)"
            data-type="number"
            format="percent"
            cell-template="CTClickRate"
          >
            <DxFormat
              type="fixedPoint"
              :precision="2"
            />
          </DxColumn>
          <!-- <DxColumn
            data-field="clickToOpenRate"
            caption="Click to Open Rate (%)"
            data-type="number"
            format="percent"
            cell-template="CTClickToOpenRate"
          >
            <DxFormat
              type="fixedPoint"
              :precision="2"
            />
          </DxColumn> -->
          <DxColumnChooser
            :enabled="true"
            :allow-search="false"
            mode="select"
          />
          <template #CTReceived="{ data: options }">
            <ServiceLineReportCellTemplate
              metric-name="received"
              :cell-data="options.data"
              :comparison-cell-data="comparisonValue === 'MMS Benchmark' ? {} : getComparisonSpecialtyEngagement(options.data)"
              format="number"
            />
          </template>
          <template #CTReceivedRate="{ data: options }">
            <ServiceLineReportCellTemplate
              metric-name="receivedRate"
              :cell-data="options.data"
              :comparison-cell-data="comparisonValue === 'MMS Benchmark' ? {} : getComparisonSpecialtyEngagement(options.data)"
              format="percent"
              :color-code-comparison="false"
            />
          </template>
          <template #CTReadRate="{ data: options }">
            <ServiceLineReportCellTemplate
              metric-name="readRate"
              :cell-data="options.data"
              :comparison-cell-data="getComparisonSpecialtyEngagement(options.data)"
              format="percent"
            />
          </template>
          <template #CTOpenRate="{ data: options }">
            <ServiceLineReportCellTemplate
              metric-name="openRate"
              :cell-data="options.data"
              :comparison-cell-data="getComparisonSpecialtyEngagement(options.data)"
              format="percent"
            />
          </template>
          <template #CTClickRate="{ data: options }">
            <ServiceLineReportCellTemplate
              metric-name="clickRate"
              :cell-data="options.data"
              :comparison-cell-data="getComparisonSpecialtyEngagement(options.data)"
              format="percent"
            />
          </template>
          <template #CTClickToOpenRate="{ data: options }">
            <ServiceLineReportCellTemplate
              metric-name="clickToOpenRate"
              :cell-data="options.data"
              :comparison-cell-data="getComparisonSpecialtyEngagement(options.data)"
              format="percent"
            />
          </template>
        </DxTreeList>
        <v-skeleton-loader
          v-else-if="specialtyEngagementBase.specialtyEngagementsLoading"
          type="image@3"
        />
        <div v-else>
          Select a date range to see AMA Service Line data.
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>

import {
  inputMonthFormat,
  displayDateFormat,
  displayMonthFormat,
  inputDateFormat,
  calendarEventFormat,
  displayTimeFormat2
} from '@/shared/constants'
import {
  DxTreeList,
  DxRemoteOperations,
  DxColumn,
  DxSearchPanel,
  DxHeaderFilter,
  DxSelection,
  DxFilterRow,
  DxColumnChooser,
  DxFormat,
  DxFilterPanel,
  DxFilterBuilderPopup
} from 'devextreme-vue/tree-list'
import mutationTypes from '@/store/mutation-types'
import { mapGetters } from 'vuex'
import claims from '@/shared/models/auth/claims'
import { mailingService } from '@/shared/services'
import moment from 'moment'
import { windowMixin } from '@/shared/mixins/general'

export default {
  name: 'SpecialtyEngagementReport',

  components: {
    DxTreeList,
    DxRemoteOperations,
    DxColumn,
    DxSearchPanel,
    DxHeaderFilter,
    DxSelection,
    DxFilterRow,
    DxColumnChooser,
    DxFormat,
    DxFilterPanel,
    // DxFilterBuilderPopup,
    ViewAsCustomer: () => import('@/views/pages/components/user-state/ViewAsCustomer'),
    ServiceLineReportCellTemplate: () => import('@/views/pages/reports/ServiceLineReportCellTemplate')
  },

  mixins: [windowMixin],

  data () {
    return {
      specialtyEngagementBase: {
        name: 'Base',
        dateMenu: false,
        dates: [
          moment().subtract(1, 'month').local().format(inputMonthFormat),
          moment().local().format(inputMonthFormat)
        ],
        specialtyEngagements: [],
        specialtyEngagementsLoading: false
      },
      specialtyEngagementComparison: {
        name: 'Comparison',
        dateMenu: false,
        dates: [
          moment().subtract(1, 'month').local().format(inputMonthFormat),
          moment().local().format(inputMonthFormat)
        ],
        specialtyEngagements: [],
        specialtyEngagementsLoading: false
      },
      currentMonth: moment().local().format(),
      expandedRowKeys: [],
      comparisonValue: null,
      comparisonItems: ['My Data', 'MMS Benchmark'],
      // percentHeaderFilters: [{
      //   text: 'Less than $3000',
      //   value: ['SaleAmount', '<', 3000]
      // }, {
      //   text: '$3000 - $5000',
      //   value: [['SaleAmount', '>=', 3000], ['SaleAmount', '<', 5000]]
      // }, {
      //   text: '$5000 - $10000',
      //   value: [['SaleAmount', '>=', 5000], ['SaleAmount', '<', 10000]]
      // }, {
      //   text: '$10000 - $20000',
      //   value: [['SaleAmount', '>=', 10000], ['SaleAmount', '<', 20000]]
      // }, {
      //   text: 'Greater than $20000',
      //   value: ['SaleAmount', '>=', 20000]
      // }],
      filterBuilderPopupPosition: {
        of: window,
        at: 'top',
        my: 'top',
        offset: { y: 10 }
      },
      unsubscribe: null
    }
  },

  watch: {
    'specialtyEngagementBase.dates': {
      handler (newValue, oldValue) {
        if (newValue && newValue.length === 2) {
          if (moment(newValue[0]).isAfter(moment(newValue[1]))) {
            this.specialtyEngagementBase.dates = [
              newValue[1],
              newValue[0]
            ]
            return
          }
          this.getSpecialtyEngagements(this.specialtyEngagementBase)
        }
      }
    },
    'specialtyEngagementComparison.dates': {
      handler (newValue, oldValue) {
        if (newValue && newValue.length === 2) {
          if (moment(newValue[0]).isAfter(moment(newValue[1]))) {
            this.specialtyEngagementComparison.dates = [
              newValue[1],
              newValue[0]
            ]
            return
          }
          this.getSpecialtyEngagements(this.specialtyEngagementComparison)
        }
      }
    },
    comparisonValue (newValue, oldValue) {
      if (newValue) {
        this.getSpecialtyEngagements(this.specialtyEngagementComparison)
      } else {
        this.specialtyEngagementComparison.specialtyEngagements = []
      }
    }
  },

  created () {
    this.getSpecialtyEngagements(this.specialtyEngagementBase)
  },

  mounted () {
    this.unsubscribe = this.$store.subscribe(mutation => {
      let listeners = [
        mutationTypes.SET_VIEW_AS_CUSTOMER
      ]
      if (listeners.includes(mutation.type)) {
        this.getSpecialtyEngagements(this.specialtyEngagementBase)
        if (this.comparisonValueSelected) {
          this.getSpecialtyEngagements(this.specialtyEngagementComparison)
        }
      }
    })
  },

  destroyed () {
    this.unsubscribe()
  },

  computed: {
    ...mapGetters('auth', ['hasClaimKV']),
    showViewAsCustomer () {
      return this.hasClaimKV(claims.MMS_TEBT)
    },
    minMonth () {
      return moment().subtract(24, 'months')
    },
    comparisonValueSelected () {
      return this.comparisonValue !== null && this.comparisonValue !== undefined
    },
    title1 () {
      if (this.specialtyEngagementBase.dates.length !== 2) return 'My Data ...'
      var date1Label = moment(this.specialtyEngagementBase.dates[0]).isSame(moment(this.specialtyEngagementBase.dates[1]), 'month')
        ? `for the month of ${this.dateRangeText(this.specialtyEngagementBase.dates)}` : `from ${this.dateRangeText(this.specialtyEngagementBase.dates)}`
      return `My Data ${date1Label}`
    },
    title2 () {
      if (!this.comparisonValueSelected) return ''
      if (this.specialtyEngagementComparison.dates.length !== 2) return `(compared to ${this.comparisonValue} ...)`
      var date2Label = moment(this.specialtyEngagementComparison.dates[0]).isSame(moment(this.specialtyEngagementComparison.dates[1]), 'month')
        ? `for the month of ${this.dateRangeText(this.specialtyEngagementComparison.dates)}` : `from ${this.dateRangeText(this.specialtyEngagementComparison.dates)}`
      return ` (compared to ${this.comparisonValue} ${date2Label})`
    }
  },

  methods: {
    async getSpecialtyEngagements (specialtyEngagement) {
      specialtyEngagement.specialtyEngagementsloading = true
      specialtyEngagement.specialtyEngagements = []
      if (specialtyEngagement.name === 'Base') this.expandedRowKeys = []
      await mailingService.getSpecialtyEngagement({
        customerNowwId: specialtyEngagement.name === 'Comparison' && this.comparisonValue === this.comparisonItems[1] ? -1 : this.$store.getters['simulatedCustomerNowwId'](),
        startDate: moment(specialtyEngagement.dates[0]).startOf('month').format(inputDateFormat),
        endDate: moment(specialtyEngagement.dates[1]).endOf('month').format(inputDateFormat)
      }).then(resp => {
        if (resp.errorResponse) {
          this.$store.commit('setGlobalSnackbar', {
            message: resp.errorResponse,
            color: 'error'
          })
        } else {
          specialtyEngagement.specialtyEngagements = resp
        }
      }).finally(() => {
        specialtyEngagement.specialtyEngagementsloading = false
      })
    },
    dateRangeText (dates) {
      if (!dates || dates.length !== 2) return ''
      if (moment(dates[0]).isSame(moment(dates[1]), 'month')) return moment(dates[0]).format(displayMonthFormat)
      return dates.map(x => moment(x).format(displayMonthFormat)).join(' - ')
    },
    swapDates () {
      var temp = this.specialtyEngagementBase.dates
      this.specialtyEngagementBase.dates = this.specialtyEngagementComparison.dates
      this.specialtyEngagementComparison.dates = temp
    },
    getComparisonSpecialtyEngagement (row) {
      if (!this.specialtyEngagementComparison || !this.specialtyEngagementComparison.specialtyEngagements || this.specialtyEngagementComparison.specialtyEngagements.length === 0) {
        return null
      }
      return this.specialtyEngagementComparison.specialtyEngagements.find(x => x.marketingOffer === row.marketingOffer &&
          x.specialtyGroupName === row.specialtyGroupName &&
          x.specialtyName === row.specialtyName)
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
